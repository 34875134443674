
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 384 512"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M384 128H272a16 16 0 01-16-16V0H24A23.94 23.94 0 000 23.88V488a23.94 23.94 0 0023.88 24H360a23.94 23.94 0 0024-23.88V128zM160 404a12 12 0 01-20.5 8.5L104 376H76a12 12 0 01-12-12v-56a12 12 0 0112-12h28l35.5-36.48A12 12 0 01160 268zm33.24-51.13a24.05 24.05 0 000-33.57c-22.08-22.91 12.2-56.48 34.38-33.66a72.64 72.64 0 010 100.9c-21.78 22.38-56.86-10.48-34.38-33.7zm86.2 83.66c-21.67 22.4-56.66-10.31-34.19-33.52a96.39 96.39 0 000-133.85c-22-22.81 12.22-56.32 34.19-33.51 54.08 55.87 54.08 144.94 0 200.85z","fill":"currentColor","opacity":".4"}}),_c('path',{attrs:{"d":"M377 105L279.1 7a24 24 0 00-17-7H256v112a16 16 0 0016 16h112v-6.1a23.9 23.9 0 00-7-16.9zM148 256a12 12 0 00-8.53 3.53L104 296H76a12 12 0 00-12 12v56a12 12 0 0012 12h28l35.5 36.48A12 12 0 00160 404V268a12 12 0 00-12-12zm79.59 29.61c-22.18-22.82-56.46 10.75-34.38 33.66a24.05 24.05 0 010 33.57c-22.48 23.22 12.6 56.08 34.38 33.67a72.64 72.64 0 00.03-100.9zm51.82-50c-22-22.81-56.16 10.7-34.19 33.51a96.39 96.39 0 010 133.85c-22.47 23.21 12.52 55.92 34.19 33.52 54.08-55.91 54.08-145 0-200.88z","fill":"currentColor"}})])
          )
        }
      }
    